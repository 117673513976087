import React from "react";
import * as css from "./Input.2.module.css";

import Code from "./Code";
import { Heading4 } from "../docs/Heading";
import InputComponent from "../docs/InputComponent";

import {
  widont,
  useCodeTagsParsed,
  useAnchorTagsParsed
} from "../../utils/index.js";

import * as docsCss from "../../style/docs.module.css";

const PropertyItem = ({ property }) => {
  const description = useCodeTagsParsed(property.description);
  return (
    <li>
      <span>
        <code className={docsCss.highlight}>{property.name}</code>
      </span>
      : {description}
    </li>
  );
};

export const Input = ({ data }) => {
  const { name, title, description, attributes, examples, properties } = data;
  const parsedDescription = useAnchorTagsParsed(widont(description));
  return (
    <div className={css.root}>
      <Heading4 id={name.toLowerCase().replace(/\s/g, "-")}>{name}</Heading4>
      <div className={css.details}>
        <p className={css.leftText}>{parsedDescription}</p>
        {attributes && (
          <InputComponent
            className={css.column}
            name={title}
            attributes={JSON.parse(attributes)}
          />
        )}
      </div>
      {properties && (
        <>
          <p className={css.leftText}>
            {name} accepts the following properties:
          </p>
          <ul className={docsCss.inputProperties}>
            {properties.map((property, index) => (
              <PropertyItem key={index} property={property} />
            ))}
          </ul>
        </>
      )}
      {examples && (
        <>
          <p className={css.leftText}>Here are some examples:</p>
          {examples.map((example, index) => (
            <div key={index} className={css.details}>
              <Code className={css.codeColumn} variant="blue">
                {example.code}
              </Code>
              <InputComponent
                className={css.column}
                name={title}
                attributes={JSON.parse(example.attributes)}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Input;
