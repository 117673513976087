import React, { useState } from "react";
import { graphql } from "gatsby";
import classnames from "classnames";

import Layout from "../../components/Layout";
import Header from "../../components/docs/Header";
import { Heading3, Heading4 } from "../../components/docs/Heading";
import VersionNav from "../../components/docs/VersionNav";
import Footer from "../../components/common/Footer";
import Subscribe from "../../components/common/Subscribe";
import Code from "../../components/common/Code";
import ShortCode from "../../components/common/ShortCode";
import SuccessPopup from "../../components/common/SuccessPopup";
import DocMenu from "../../components/docs/DocMenu";
import Feedback from "../../components/common/Feedback";
import Input from "../../components/common/Input.2";
import InputComponent from "../../components/docs/InputComponent";
import Note from "../../components/docs/Note";

import { useHighlight, useScrollProgress } from "../../utils/hooks";

import * as grid from "../../style/grid.module.css";
import * as css from "../../style/docs.module.css";

const items = [
  {
    name: "Get started",
    children: [
      { name: "Design functions" },
      { name: "Templates" },
      { name: "Examples" }
    ]
  },
  {
    name: "Add a function"
  },
  {
    name: "Add an input",
    children: [
      { name: "Text input" },
      { name: "Number input" },
      { name: "Color input" },
      { name: "Boolean input" },
      { name: "Image input" },
      { name: "Custom Input" }
    ]
  },
  {
    name: "Add a preset"
  },
  {
    name: "Handler arguments"
  },
  {
    name: "Select an engine",
    children: [
      { name: "Canvas" },
      { name: "SVG" },
      { name: "p5.js" },
      { name: "React" }
    ]
  },
  {
    name: "Settings"
  },
  {
    name: "Advanced use",
    children: [
      { name: "Static folder" },
      { name: "Custom inputs" },
      { name: "Custom sidebar" },
      { name: "Preloading" }
    ]
  },
  {
    name: "Dive deeper"
  }
];

const DocsPage = ({ data, location }) => {
  const [subscribed, setSubscribed] = useState();
  const progress = useScrollProgress();
  useHighlight();

  const inputs = data.inputs.nodes.sort(
    (a, b) => a.childJson.order - b.childJson.order
  );
  const templates = data.templates.nodes.sort((a, b) =>
    a.childJson.name.localeCompare(b.childJson.name)
  );
  const examples = data.examples.nodes.sort((a, b) =>
    a.childJson.name.localeCompare(b.childJson.name)
  );

  return (
    <Layout title="Mechanic Documentation" className={css.root}>
      <Header
        className={css.header}
        logo={data.logo}
        title={"Documentation"}
        progress={progress}>
        <VersionNav version={location.pathname} />
      </Header>

      <Feedback href="https://forms.gle/uBTn8oVThZHVghV89">
        Got feedback?
      </Feedback>

      <main className={classnames(grid.grid, css.main)}>
        <DocMenu items={items} />

        <div className={classnames(css.docs)}>
          <div className={css.section}>
            <Heading3 id="get-started">Get started</Heading3>

            <p className={css.leftText}>
              Mechanic* is a powerful open source framework that helps
              forward-looking designers move away from a manual design workflow
              by converting their design rules into&nbsp;tools.
              <br />
              <br />
              To start working with Mechanic, you need to create a project. Run
              the following command in the&nbsp;terminal:
            </p>

            <ShortCode copyText="npm init mechanic@latest my-project">
              npm init mechanic@latest my-project
            </ShortCode>

            <Note>
              Make sure you have{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://nodejs.org/en/download/">
                {" "}
                Node.js
              </a>{" "}
              (version 12.20 or greater) and{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.npmjs.com/about-npm-versions">
                npm
              </a>{" "}
              installed.
            </Note>

            <div className={css.leftText}>
              <p>
                This will install the Mechanic command-line interface and prompt
                you with instructions on how to customize your new Mechanic
                project, including your very first design function. You can
                choose between creating a new design function based on a{" "}
                <a href="#templates">template</a> or a more sophisticated{" "}
                <a href="#examples">example</a>.
              </p>

              <p>
                As a result, you will end up with a folder with your chosen
                project name and the following files&nbsp;in it:
              </p>

              <ul className={css.fileList}>
                <li>
                  <span>
                    <code className={css.highlight}>README.md</code>
                  </span>{" "}
                  contains some pointers on how to run your
                  Mechanic&nbsp;project{" "}
                </li>
                <li>
                  <span>
                    <code className={css.highlight}>package.json</code>
                  </span>{" "}
                  contains all the dependencies needed to use&nbsp;Mechanic{" "}
                </li>
                <li>
                  <span>
                    <code className={css.highlight}>mechanic.config.js</code>
                  </span>{" "}
                  contains the Mechanic&nbsp;configuration
                </li>
                <li>
                  <span>
                    <code className={css.highlight}>functions/</code>
                  </span>{" "}
                  is a folder that will contain all your design&nbsp;functions{" "}
                </li>
                {/* <li>
                  <span>
                    <code className={css.highlight}>input/</code>
                  </span>{" "}
                  is a folder that will contain your custom inputs{" "}
                </li>
                <li>
                  <span>
                    <code className={css.highlight}>static/</code>
                  </span>{" "}
                  is a folder for assets that can be used across all functions{" "}
                </li>
                <li>
                  <span>
                    <code className={css.highlight}>app/</code>
                  </span>{" "}
                  is a folder for defining a custom sidebar. Read more{" "}
                  <a href="#custom-sidebar">here</a>.{" "}
                </li> */}
              </ul>
            </div>
          </div>
          <div className={css.section}>
            <Heading3 id="design-functions">Design functions</Heading3>
            <p className={css.leftText}>
              Design functions are JavaScript functions that generate a design
              asset. They are the main building block of Mechanic, and a project
              can have one or more design functions. Based on the content in the
              design functions, Mechanic creates a design tool you will be able
              to use in your web&nbsp;browser.
              <br />
              <br />
              The generated asset can be either static or animated, simple or
              complex. A design function creates assets using a specific web
              technology and a corresponding Mechanic engine that enables it.
              Currently, the available options for{" "}
              <a href="#select-an-engine">engines</a> are SVG, Canvas, React.js
              and&nbsp;p5.js. <br />
              <br />
              Each design function definition should live in a separate folder
              in the <code className={css.highlight}>functions/</code> folder.
              Each function folder will contain an{" "}
              <code className={css.highlight}>index.js</code> file with the
              following&nbsp;elements:
            </p>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>Main handler</h4>
                <p>
                  This is where you write the code that produces your design,
                  and this code will be specific to the chosen engine. A{" "}
                  <code className={css.highlight}>handler</code> function always
                  receives a single argument, and your code can produce variable
                  designs based on the values in this&nbsp;object.{" "}
                  <a href="#handler-arguments">
                    Learn more about handler arguments
                  </a>
                  .
                </p>
              </div>
              <pre className={css.firstPart}>
                <code>
                  {`export const handler = ({ inputs, mechanic }) => {

  //  Implementation of the design goes here

};`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>inputs</h4>
                <p>
                  <code className={css.highlight}>inputs</code> is an object
                  defining the variables that will be passed to your design
                  function. Each input will create an element in the design tool
                  UI, and users can change these inputs and run the function
                  with updated&nbsp;values.{" "}
                  <a href="#add-an-input">Learn more about inputs</a>.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>
                  {`// Specify as many inputs as you want
export const inputs = {
  input1: {
    type: "number",
    ...
  },
  input2: {
    type: "color",
    ...
  },
  ...
};`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>Presets</h4>
                <p>
                  Presets give you the opportunity to set predefined sets of
                  values for the inputs that users can select with a single
                  click in the design tool&nbsp;UI.{" "}
                  <a href="#add-a-preset">Learn more about presets</a>.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>
                  {`// Specify as many presets as you want
export const presets = {
  preset: {
    input1: 2,
    input2: "#454545",
    ...
  }
};`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>Settings</h4>
                <p>
                  Settings allow you to set general configuration for your
                  function. This is where you specify the engine that your
                  design function will be using and whether the design function
                  produces a static or animated&nbsp;asset.{" "}
                  <a href="#settings">Learn more about settings</a>.
                </p>
              </div>
              <pre className={css.lastPart}>
                <code>
                  {`// General configuration for your function
export const settings = {
  engine: require("@mechanic-design/engine_name"),
  animated: false,
  optimize: true,
};`}
                </code>
              </pre>
            </div>
          </div>
          <div className={css.section}>
            <Heading3 id="templates">Templates</Heading3>
            <p className={css.leftText}>
              Templates are simple design functions created to show you how to
              use Mechanic with specific web technologies for either animated or
              static assets. You can use one to get to know Mechanic, or use one
              as a base to start your design&nbsp;function.
            </p>
            <p className={css.leftText}>
              Use the{" "}
              <code className={css.highlight}>npm init mechanic@latest</code>{" "}
              command to create a new Mechanic project with a single design
              function based on a template. Once you have created your Mechanic
              project, you can use the{" "}
              <code className={css.highlight}>npm run new</code> command to add
              more design&nbsp;functions.
            </p>
            <p className={css.leftText}>
              The currently available templates you can find&nbsp;are:
            </p>
            {templates.map((e, key) => (
              <ul key={key} className={css.leftList}>
                <li>
                  <span className={css.listLabel}>{e.childJson.name}</span>:{" "}
                  <span>{e.childJson.description}</span>
                </li>
              </ul>
            ))}
            <p className={css.leftText}>
              Let's take a closer look at the p5.js video template, which
              produces an animated&nbsp;asset.
            </p>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>p5.js video</h4>
                <p>
                  When using the p5.js engine, the handler function argument
                  will have three properties:{" "}
                  <code className={css.highlight}>inputs</code> are the values
                  of the inputs defined below,{" "}
                  <code className={css.highlight}>mechanic</code> holds API
                  functions to record the frames of the animation, and{" "}
                  <code className={css.highlight}>sketch</code> is the p5.js
                  sketch that you can use to invoke p5.js functions. This last
                  argument is specific to this&nbsp;engine.
                </p>
              </div>
              <pre className={css.firstPart}>
                <code>
                  {`export const handler = ({inputs, mechanic, sketch}) => {
  // Extract values of the inputs
  const { width, height, radiusPercentage } = inputs;
  const radius = ((height / 2) * radiusPercentage) / 100;
  let angle = 0;

  // The p5.js draw function is called 60 fps
  sketch.draw = () => {
    sketch.rotate(angle);
    sketch.arc(0, 0, 2 * radius, 2 * radius, -sketch.PI, 0);
    sketch.arc(0, 0, 2 * radius, 2 * radius, 0, sketch.PI);
    sketch.rotate(-angle);
`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  The <code className={css.highlight}>mechanic.frame()</code>{" "}
                  function is used to record each individual frame of the
                  animation, while the{" "}
                  <code className={css.highlight}>mechanic.done()</code>{" "}
                  function tells Mechanic that the asset has finished rendering.
                  You can read more about these API functions in{" "}
                  <a href="#handler-arguments">handler arguments</a>.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>
                  {`    if (angle < turns * 2 * Math.PI) {
      mechanic.frame();
      angle += (2 * Math.PI) / 100;
    } else {
      mechanic.done();
    }
  };
};

export const inputs = {
  width: {
    type: "number",
    default: 400,
  },
  height: {
    type: "number",
    default: 300,
  },
  radiusPercentage: {
    type: "number",
    default: 40,
    min: 0,
    max: 100,
    slider: true,
  },
  // ...
};

export const presets = {
  medium: {
    width: 800,
    height: 600,
  },
  // ...
};
`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  The settings define that this design function uses the p5.js
                  engine via the{" "}
                  <code className={css.highlight}>
                    @mechanic-design/engine-p5
                  </code>{" "}
                  package. Also, since this asset is animated and uses the{" "}
                  <code className={css.highlight}>frame()</code> method, this is
                  specified in the settings&nbsp;too.
                </p>
              </div>
              <pre className={css.lastPart}>
                <code>
                  {`export const settings = {
  engine: require("@mechanic-design/engine-p5"),
  animated: true,
};`}
                </code>
              </pre>
            </div>
          </div>
          <div className={css.section}>
            <Heading3 id="examples">Examples</Heading3>
            <p className={css.leftText}>
              Examples are more complex design functions created to show you how
              to use Mechanic to tackle some more advanced use cases. Currently,
              Mechanic has three examples, and more may be added along
              the&nbsp;way.
            </p>

            <p className={css.leftText}>
              Use the{" "}
              <code className={css.highlight}>npm init mechanic@latest</code>{" "}
              command to create a new Mechanic project with a single design
              function based on an example. Once you have created your Mechanic
              project, you can use the{" "}
              <code className={css.highlight}>npm run new</code> command to add
              more design&nbsp;functions.
            </p>
            {examples.map((e, key) => (
              <ul key={key} className={css.leftList}>
                <li>
                  <h4>{e.childJson.name}</h4>
                  <p className={css.listParagraph}>{e.childJson.description}</p>
                </li>
              </ul>
            ))}
          </div>
          <div className={css.section}>
            <Heading3 id="add-a-function">Add a function</Heading3>
            <p className={css.leftText}>
              You can always add more design functions to a Mechanic project. To
              do so, run the following command in the terminal in your mechanic
              project folder. This will create a new folder in your Mechanic
              project with an <code className={css.highlight}>index.js</code>{" "}
              file based on a template or an&nbsp;example.
            </p>
            <ShortCode copyText="npm run new">npm run new</ShortCode>
            <p className={classnames(css.leftText, css.lower)}>
              Alternatively, you can create the corresponding folders and files
              manually to add a new design function. Make sure you follow a
              similar folder structure as the one mentioned and that you add the
              necessary dependencies to you project to make it&nbsp;work.
            </p>
          </div>
          <div className={css.section}>
            <Heading3 id="add-an-input">Add an input</Heading3>
            <p className={css.leftText}>
              <code className={css.highlight}>inputs</code> is an object
              defining the variables that will be passed to your design
              function. Each input will create an element in the design tool UI,
              and users can change their values using these inputs and run the
              function with updated values.
            </p>

            <p className={css.leftText}>
              There are many types of inputs and each of them have different
              settings that change their behavior. Once defined, the input will
              be accessible in the handler function via the name provided in the{" "}
              <code className={css.highlight}>inputs</code> object.
            </p>
            <div className={css.twoColumns}>
              <div className={css.code}>
                <span> Definition </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`const inputs = {
  name: {
    type: "text",
    default: "my name",
  },
  year: {
    type: "number",
    default: 2022,
  }
};`}
                </Code>
              </div>
              <div className={css.code}>
                <span> Use in handler </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`export const handler = ({ inputs, mechanic }) => {
  const { name, year } = inputs;
  // Use "name" and "year" in your code...
};`}
                </Code>
              </div>
            </div>

            <p className={css.leftText}>
              The <code className={css.highlight}>type</code> property is the
              minimum required property you need to define an input. It's a
              string value that determines the kind of input that you need, the
              type of object that the handler will receive as a value and how it
              looks in the UI. Mechanic provides out of the box a bunch input
              types (<code className={css.highlight}>"number"</code>,{" "}
              <code className={css.highlight}>"text"</code>,{" "}
              <code className={css.highlight}>"color"</code>,{" "}
              <code className={css.highlight}>"boolean"</code>, and{" "}
              <code className={css.highlight}>"image"</code>), but you can also
              create <a href="#custom-inputs">your own</a>.
            </p>

            <p className={css.leftText}>
              By default all inputs are debounced with a delay of 100
              milliseconds. This behavior can be adjusted using{" "}
              <code className={css.highlight}>debounceDelay</code> and{" "}
              <code className={css.highlight}>debounceInputs</code> in the{" "}
              <a href="#settings">design function’s settings</a>.
            </p>

            <p className={css.leftText}>
              Certain input types have more properties to customize them, and
              some of them may be required as part of their definition. The
              following are the most common input properties available:
            </p>

            <ul className={css.inputProperties}>
              <li>
                <span>
                  <code className={css.highlight}>default</code>
                </span>
                : value that acts as a fallback for input value if not yet set
                by the user. This property is available for most types of
                inputs, except for{" "}
                <code className={css.highlight}>"image"</code>.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>validation</code>
                </span>
                : a function that receives the current value assigned to the
                input and outputs an error (as a string) when the value entered
                doesn't pass a certain condition. When the given function
                returns an error string, the error is shown in the UI. If the
                value passes conditions (no error) a{" "}
                <code className={css.highlight}>null</code> value
                is&nbsp;expected.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>editable</code>
                </span>
                : either a boolean value or a function that receives all current
                values assigned to the defined inputs and determines if the
                input can be edited or not. If a function is set, a boolean
                value is expected as an output for that function.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>label</code>
                </span>
                : string value to use as label for input in UI input. If not
                set, by default the input name is used as the label for
                the&nbsp;UI.
              </li>
            </ul>

            {inputs.map((e, key) => (
              <ul key={key} className={css.inputs}>
                <li>
                  <Input data={e.childJson} />
                </li>
              </ul>
            ))}
          </div>

          <div className={css.section}>
            <Heading3 id="add-a-preset">Add a preset</Heading3>
            <p className={css.leftText}>
              Presets give you the opportunity to set predefined sets of values
              for the inputs that users can select with a single click in the
              design tool UI. You can have multiple presets per function, which
              allow the user to quickly select a number of values in a
              single&nbsp;action.
            </p>
            <div className={css.presetCode}>
              <div className={css.code}>
                <Code variant="blue" className={css.codeColumn}>
                  {`export const presets = {
  presetName1: {
    input1: value,
    input2: value,
    // ...
  },
  presetName2: {
    input1: value,
    input2: value,
    // ...
  },
};`}
                </Code>
              </div>
              <InputComponent
                className={css.presetInput}
                name="Presets"
                attributes={{
                  type: "text",
                  options: [
                    "no presets",
                    "presetName1",
                    "presetName2",
                    "default values"
                  ],
                  default: "no presets"
                }}
              />
            </div>
          </div>

          <div className={css.section}>
            <Heading3 id="handler-arguments">Handler arguments</Heading3>
            <p className={css.leftText}>
              The handler function of a design function always receives a single
              argument with at least two properties that are key to the
              function's rendering:{" "}
              <code className={css.highlight}>inputs</code> and{" "}
              <code className={css.highlight}>mechanic</code>. Depending on the{" "}
              engine, the handler function may receive more properties in the
              object&nbsp;argument.{" "}
              <a href="#select-an-engine">
                Read more about the individual engines
              </a>
              .
            </p>

            <ul className={css.handlerArguments}>
              <li>
                <span>
                  <code className={css.highlight}>inputs</code>:&nbsp;
                </span>
                The <code className={css.highlight}>inputs</code> property will
                contain all values selected through the UI interface for each
                defined input. Your design function can use these values to
                change what is being&nbsp;rendered.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>mechanic</code>:&nbsp;
                </span>
                The <code className={css.highlight}>mechanic</code> property
                serves as a proxy to Mechanic’s core functionality, wrapping two
                important methods:{" "}
                <code className={css.highlight}>mechanic.done()</code> and{" "}
                <code className={css.highlight}>mechanic.frame()</code>. The{" "}
                <code className={css.highlight}>done()</code> method tells
                Mechanic that the code to generate an asset has finished. This
                function must be called at some point in the handler's code for
                both static and animated assets. For animated assets, the{" "}
                <code className={css.highlight}>frame()</code> method adds
                separate frames of the asset and is expected to be called before
                the call to&nbsp;<code className={css.highlight}>done()</code>.
              </li>
            </ul>
          </div>
          <div className={css.section}>
            <Heading3 id="select-an-engine">Select an engine</Heading3>
            <p className={classnames(css.leftText, css.engineText)}>
              Engines are JavaScript functions that enable certain web
              technologies with Mechanic’s environment. Currently there are four
              official engines published. It is unlikely you will need to write
              an engine from scratch, but the offical engines should be a good
              guide in case you want to enable a non-supported JavaScript
              library to work with&nbsp;Mechanic.
            </p>
            <Heading4 id="canvas">Canvas</Heading4>
            <p className={classnames(css.leftText, css.engineText)}>
              This engine enables the use of the{" "}
              <a href="https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API">
                Canvas API
              </a>{" "}
              in the Mechanic framework. While using this engine, both the{" "}
              <code className={css.highlight}>frame()</code> and the{" "}
              <code className={css.highlight}>done()</code> method expect to be
              called with a single argument: the canvas object that the design
              function is using to render the&nbsp;asset.
            </p>
            <div className={css.twoColumns}>
              <div className={css.code}>
                <span> Definition </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`const settings = {
  engine: require("@mechanic-design/engine-canvas"),
};`}
                </Code>
              </div>
              <div className={css.code}>
                <span> Use in handler </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`export const handler = ({ inputs, mechanic }) => {
  const { width, height, color } = inputs;
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  ctx.fillStyle = color;
  // ...
  mechanic.done(canvas)
};`}
                </Code>
              </div>
            </div>

            <Heading4 id="svg" className={css.engineTitle}>
              SVG
            </Heading4>
            <p className={classnames(css.leftText, css.engineText)}>
              This engine enables the use of{" "}
              <a href="https://developer.mozilla.org/en-US/docs/Web/SVG">
                SVG strings
              </a>{" "}
              in the Mechanic framework. While using this engine, both methods
              of the <code className={css.highlight}>mechanic</code> handler
              parameter are expected to be called with a single argument: the
              string value of the SVG&nbsp;image.
            </p>
            <div className={css.twoColumns}>
              <div className={css.code}>
                <span> Definition </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`const settings = {
  engine: require("@mechanic-design/engine-svg"),
};`}
                </Code>
              </div>
              <div className={css.code}>
                <span> Use in handler </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`export const handler = ({ inputs, mechanic }) => {
  const { width, height, color } = inputs;
  const svg = \`<svg width="\${width}" height="\${height}">
      <rect fill="\${color}" width="\${width}" height="\${height}" />
    </svg>\`

  mechanic.done(svg);
};`}
                </Code>
              </div>
            </div>

            <Heading4 id="p5.js" className={css.engineTitle}>
              p5.js
            </Heading4>
            <p className={classnames(css.leftText, css.engineText)}>
              This engine enables the use of{" "}
              <a href="https://p5js.org/">p5.js</a> in the Mechanic framework.
              Handlers that use this engine also receive a{" "}
              <code className={css.highlight}>sketch</code> parameter which
              allows the use of p5.js in{" "}
              <a href="https://p5js.org/reference/#/p5/p5">instance mode</a> to
              invoke all p5.js functions.
            </p>
            <div className={css.twoColumns}>
              <div className={css.code}>
                <span> Definition </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`const settings = {
  engine: require("@mechanic-design/engine-p5"),
};`}
                </Code>
              </div>
              <div className={css.code}>
                <span> Use in handler </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`export const handler = ({ inputs, mechanic, sketch }) => {
  const { width, height, color } = inputs;
  sketch.setup = () => {
    sketch.createCanvas(width, height);
  };

  sketch.draw = () => {
    sketch.background(color);
    mechanic.done();
  };
};`}
                </Code>
              </div>
            </div>

            <Heading4 id="react" className={css.engineTitle}>
              React
            </Heading4>
            <p className={classnames(css.leftText, css.engineText)}>
              This engine enables the use of the{" "}
              <a href="https://reactjs.org/">React SVG components</a> in the
              Mechanic framework.
            </p>
            <div className={css.twoColumns}>
              <div className={css.code}>
                <span> Definition </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`const settings = {
  engine: require("@mechanic-design/engine-react"),
};`}
                </Code>
              </div>
              <div className={css.code}>
                <span> Use in handler </span>
                <Code variant="blue" className={css.codeColumn}>
                  {`export const handler = ({ inputs, mechanic }) => {
  const { width, height, color } = inputs;
  useEffect(() => {
    mechanic.done();
  }, []);

  return (
    <svg width={width} height={height}>
      <rect fill={color} width={width} height={height} />
    </svg>
  );
};`}
                </Code>
              </div>
            </div>
          </div>
          <div className={css.section}>
            <Heading3 id="settings">Settings</Heading3>
            <p className={css.leftText}>
              Settings allow you to set general configuration for your design
              function. The following are the possible properties that are
              available to be&nbsp;set.
            </p>
            <ul className={css.inputProperties}>
              <li>
                <span>
                  <code className={css.highlight}>engine</code>
                </span>
                : sets the engine for the design function. This property always
                has to be&nbsp;set.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>name</code>
                </span>
                : Set the display name for the design function in the&nbsp;UI.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>animated</code>
                </span>
                : determines whether the design function is an animated sequence
                or a static image. Defaults to{" "}
                <code className={css.highlight}>false</code>.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>persistRandomOnExport</code>
                </span>
                : if {"  "}
                <code className={css.highlight}>true</code>, enables a seeded
                random that forces the export to generate the same output as the
                last preview. Defaults to{" "}
                <code className={css.highlight}>true</code>.
              </li>

              <li>
                <span>
                  <code className={css.highlight}>optimize</code>
                </span>
                : optimizes SVG outputs using{" "}
                <a href="https://github.com/svg/svgo">SVGO</a>. If an object is
                received, it is merged with the default SVGO options and passed
                to the{" "}
                <a href="https://github.com/svg/svgo#optimize">
                  optimize function
                </a>
                . Defaults to <code className={css.highlight}>true</code>.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>hideFunction</code>
                </span>
                : When true, the whole Design Function isn't visible while
                running the app. Defaults to false.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>hideFeedback</code>
                </span>
                : When true, Mechanic's feedback button is hidden. Defaults to
                false.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>hideNavigation</code>
                </span>
                : When true, the navigation input that lets users select a
                design function is hidden. Defaults to false.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>hidePresets</code>
                </span>
                : When true, preset selection input is hidden. Defaults to
                false.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>hideScaleToFit</code>
                </span>
                : When true, Scale to Fit toggle is hidden. Defaults to false.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>initialScaleToFit</code>
                </span>
                : When false, Scale to Fit will be off initially. Defaults to
                true.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>hideAutoRefresh</code>
                </span>
                : When true, Auto Refresh toggle is hidden. Defaults to false.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>initialAutoRefresh</code>
                </span>
                : When false, Auto Refresh will be off initially. Defaults to
                true.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>hideGenerate</code>
                </span>
                : When true, Generate button is hidden. Defaults to false.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>showMultipleExports</code>
                </span>
                : When false, single export button is shown. When true, two
                separate export buttons are shown: one for PNG export and
                another for SVG export. Defaults to false.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>ignoreStyles</code>
                </span>
                : When false, CSS in iframe is injected into design function's
                SVG output. Defaults to false.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>debounceInputs</code>
                </span>
                : Debouncing is a technique that’s optimizing render performance
                by limiting the amount of design function re-renders. A design
                function will only re-render when no input has changed for the
                specified delay. When false, debouncing on the inputs is
                disabled. This means that any update to an input’s value will
                re-render the function immediately. For complex design function
                this can negatively impact render performance. Defaults to{" "}
                <code className={css.highlight}>true</code>.
              </li>
              <li>
                <span>
                  <code className={css.highlight}>debounceDelay</code>
                </span>
                : When using debounce on the inputs this value can be used to
                set the debounce delay in milliseconds. A design function will
                only re-render if there hasn't been any new input for the
                specified delay. A higher value will reduce the amount of
                re-renders, but will also make the UI feel less responsive.
                Keeping the value as low as possible and only increasing it for
                computationally complex design functions is recommended.
                Defaults to 100.
              </li>
            </ul>
          </div>
          <div className={css.section}>
            <Heading3 id="advanced-use">Advanced use</Heading3>

            <p className={css.leftText}>
              We've been slowly developing ways for you to customize the
              Mechanic interface. Apart from the settings listed above, the
              following are other ways that let's you define your own inputs and
              modify how the Mechanic UI looks.
            </p>
          </div>

          <div className={css.section}>
            <Heading3 id="static-folder">Static folder</Heading3>

            <p className={css.leftText}>
              A <code className={css.highlight}>static/</code> assets folder can
              be created in the root directory of a Mechanic project that will
              be copied over to be served along with the Mechanic app, allowing
              you to reference assets from that folder directly.
            </p>

            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>
                  <code className={css.highlight}>function-1/index.js</code>
                </h4>
              </div>
              <pre className={css.firstPart}>
                <code>
                  {`export const handler = ({ inputs, mechanic }) => {
  const { width, height } = inputs;

  useEffect(() => {
    mechanic.done();
  }, []);

  return (
    <svg width={width} height={height}>
`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  For instance, if an image gets stored in the static folder,{" "}
                  <code className={css.highlight}>static/image.png</code>, then
                  this becomes an available asset to be used in a design
                  function.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>
                  {`
      <image href="static/image.png" width={width} height={height} />
    </svg>
  );
};

`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}></div>
              <pre className={css.lastPart}>
                <code>
                  {`export const inputs = {
  width: {
    type: "number",
    default: 400,
  },
  height: {
    type: "number",
    default: 300,
  }
};

export const settings = {
  engine: require("@mechanic-design/engine-react"),
};`}
                </code>
              </pre>
            </div>
          </div>

          <div className={css.section}>
            <Heading3 id="custom-inputs">Add a custom input</Heading3>
            <p className={css.leftText}>
              Mechanic allows you to define custom inputs that are not part of
              the predefined set of ready to use inputs. Custom inputs are
              defined in a special folder{" "}
              <code className={css.highlight}>inputs/</code> at the root of a
              Mechanic project. Each custom input needs to have its own folder
              which contains an <code className={css.highlight}>index.js</code>{" "}
              file that exports all expected input definitions.
            </p>

            {/* <ul className={classnames(css.leftList, css.bottomMargin)}>
              <li>
                <span className={css.listLabel}>
                  <code className={css.highlight}>index.js</code>
                </span>
                : to define the name, properties and components of the new
                input.
              </li>
              <li>
                <span className={css.listLabel}>
                  <code className={css.highlight}>index.module.css</code>,
                </span>
                : optional, to define css for your input element
              </li>
            </ul> */}

            <p className={css.leftText}>
              Creating a custom input is a bit more complex, as you need to
              define required properties that someone can set when using the
              input, and determine what the rendered component looks like.
              Consider the following example:
            </p>

            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>
                  <code className={css.highlight}>function/index.js</code>
                </h4>
              </div>
              <pre className={css.firstPart}>
                <code>
                  {`export const handler = ({ inputs, mechanic }) => {
  const { width, height, center } = inputs;

  useEffect(() => {
    mechanic.done();
  }, []);

  return (
    <svg width={width} height={height}>
`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  Let's say we wish to have a single input type able to hold
                  both coordinates of a point.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>
                  {`      <circle cx={center.x} cy={center.y} width={10} height={10} />
    </svg>
  );
};
export const inputs = {
  width: {
    type: "number",
    default: 400,
  },
  height: {
    type: "number",
    default: 300,
  },
`}
                </code>
              </pre>
            </div>

            <div className={css.subsection}>
              <div className={css.subsectionText}>
                As all inputs, we declare it as part of the function's inputs.
                The type of it could be called{" "}
                <code className={css.highlight}>"coordinate"</code>, and have an
                object default value.
              </div>
              <pre className={css.lastPart}>
                <code>
                  {`
  center: {
    type: "coordinate",
    default: {x: 100, y: 100}
  }
};

export const settings = {
  engine: require("@mechanic-design/engine-react"),
};`}
                </code>
              </pre>
            </div>

            <p className={css.leftText}>
              To create that new type of input, you'll need to define it in the{" "}
              <code className={css.highlight}>inputs/</code> folder of the
              project:
            </p>

            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>
                  <code className={css.highlight}>
                    inputs/customInput/index.js
                  </code>
                </h4>
              </div>
              <pre className={css.firstPart}>
                <code>
                  {`import React, { useState } from "react";
`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  The <code className={css.highlight}>typeName</code> export
                  specifies the name of your custom property. If not set, then
                  the name will default to the name of the folder. In this case{" "}
                  <code className={css.highlight}>"customInput"</code>.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>
                  {`export const typeName = "coordinate";
`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  The <code className={css.highlight}>properties</code> export
                  is where you can define possible properties that a definition
                  for the custom input can adjust, like the{" "}
                  <code className={css.highlight}>min</code> and{" "}
                  <code className={css.highlight}>max</code> properties for the{" "}
                  <code className={css.highlight}>"number"</code> input. Here
                  you may define a validation function per property that makes
                  sure the used values for the property are appropriate. In this
                  case, the <code className={css.highlight}>"default"</code>{" "}
                  property should be an object with a{" "}
                  <code className={css.highlight}>x</code> and{" "}
                  <code className={css.highlight}>y</code> keys. The function
                  returns a string error if the value isn't valid, and null if
                  it does.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>
                  {` export const properties = {
  default: {
    validation: (value) => {
      if (typeof value !== "object")
        return "Property "default" should be object, not ${typeof value}";
      if (!Object.values(value).every((v) => typeof v === "number"))
        return "Property "default" object should only contain numbers. Found value that's not number.";
      if (!value.hasOwnProperty("x") || !value.hasOwnProperty("y"))
        return "Property "default" object should contain "x" and "y" keys. One is missing.";
      return null;
    },
  },
};`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  Through the{" "}
                  <code className={css.highlight}>requiredProperties</code>{" "}
                  export you can label some properties as required which means
                  that the custom input will need to have that property assigned
                  in it's definition. If not, an error will be thrown.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>{` export const requiredProperties = ["default"];`}</code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  The <code className={css.highlight}>initValue</code> export is
                  a function that returns the which value the custom input will
                  take when first shown. The function receives{" "}
                  <code className={css.highlight}>input</code> which is the
                  function definition of the corresponding input made in the
                  design function.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>
                  {`export const initValue = (input) => ({
  x: input.default.x,
  y: input.default.y
});



`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  The <code className={css.highlight}>prepareValue</code> export
                  is another function that gets called each time before the
                  design function gets called. It receives the current{" "}
                  <code className={css.highlight}>value</code> that the UI has
                  recorded for the input, and the return value is the value that
                  the design function actually gets. It could be used to change
                  the type of value being stored. The function also receives the{" "}
                  <code className={css.highlight}>input</code> definition which
                  may be useful to create fallback values.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>
                  {`export const prepareValue = (value, input) => {
  return !value ? { x: input.default.x, y: input.default.y } : value;
};
`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  The <code className={css.highlight}>Input</code> export is
                  where you specify how you want your custom input to look and
                  behave. It's expected to be a React component that will get
                  rendered as the other inputs in the sidebar.
                </p>

                <p>
                  It receives three props.{" "}
                  <code className={css.highlight}>name</code> contains the name
                  given to the input by the user (
                  <code className={css.highlight}>"center"</code> in the
                  example). That prop allows the component to get the current
                  value of the input from the{" "}
                  <code className={css.highlight}>values</code> prop, which
                  contains all current values of the design function's inputs.
                  Finally <code className={css.highlight}>onChange</code> is a
                  function that should be called whenever the value of an input
                  should be updated, and will trigger an update of the Mechanic
                  app and design function.
                </p>
              </div>
              <pre className={css.middlePart}>
                <code>
                  {`export const Input = ({ name, values, onChange }) => {
  const value = values[name];
  return (
    <div>
      {name}: {value.x}, {value.y}
      <input
        type="number"
        value={value.x}
        onChange={(e) =>
          onChange(e, name, { ...value, x: parseFloat(e.target.value) })
        }
      />
      <input
        type="number"
        value={value.y}
        onChange={(e) =>
          onChange(e, name, { ...value, y: parseFloat(e.target.value) })
        }
      />
    </div>
  );
};
`}
                </code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  Finally the{" "}
                  <code className={css.highlight}>eventHandlers</code> export
                  allows us to subscribe handlers to JS DOM events in the canvas
                  of the design function. Allowing yet another way of
                  interacting and changing an inputs value.
                </p>

                <p>
                  Each property key added should be an HTML event name that can
                  be subscribed to. And the property value is the event handler
                  that will get subscribed to the corresponding event type. The
                  returned value will update the value of the corresponding
                  input and therefore in the design function. This interface is
                  still a bit limiting, but we are working on improving it!
                </p>
              </div>
              <pre className={css.lastPart}>
                <code>
                  {`export const eventHandlers = {
  mousedown: (event) => {
    return { x: event.clientX, y: event.clientY };
  },
};`}
                </code>
              </pre>
            </div>
          </div>

          <div className={css.section}>
            <Heading3 id="custom-sidebar">Custom sidebar</Heading3>

            <p className={css.leftText}>
              Apart from all the hiding ui elements{" "}
              <a href="#settings">settings</a>, you can further customize the
              sidebar UI. Currently, you can override the wrapper element that
              contains all elements that eventually appear in the sidebar, and
              add extra elements at the bottom of the sidebar where the export
              and other buttons usually go.
            </p>

            <p className={css.leftText}>
              To override the wrapper element that contains all elements
              rendered in the sidebar, you can create an{" "}
              <code className={css.highlight}>app/</code> folder in the root of
              your Mechanic project and export from an{" "}
              <code className={css.highlight}>index.js</code> file a{" "}
              <code className={css.highlight}>SideBar</code> React component.
              This will affect the side bars of all design functions in the
              project.
            </p>

            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>
                  <code className={css.highlight}>app/index.js</code>
                </h4>
              </div>
              <pre className={css.firstPart}>
                <code>{`import React from "react";
export const SideBar = ({ children }) => {
  return (`}</code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                In this example, the wrapper element will get switch by one that
                includes a title at the top.
              </div>
              <pre className={css.middlePart}>
                <code>{`    <aside>
      <h1>This is a custom sidebar!</h1>`}</code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                Remember to use the{" "}
                <code className={css.highlight}>children</code> prop so that the
                content of the sidebar (inputs and buttons) actually get
                rendered!
              </div>
              <pre className={css.middlePart}>
                <code>{`      {children}`}</code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}></div>
              <pre className={css.lastPart}>
                <code>
                  {`
    </aside>
  );
};`}
                </code>
              </pre>
            </div>

            <p className={css.leftText}>
              To add extra elements at the bottom of the sidebar, export a{" "}
              <code className={css.highlight}>ExtraUI</code> React component
              from a design function's{" "}
              <code className={css.highlight}>index.js</code> file. Whatever
              gets rendered by this component, will be shown first in the bottom
              section where the Scale to fit and Generate buttons are. Doing
              this will only affect the side bar of the corresponding design
              functions.
            </p>

            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>
                  <code className={css.highlight}>
                    functions/my-function/index.js
                  </code>
                </h4>
              </div>
              <pre className={css.firstPart}>
                <code>{`import React from "react";
`}</code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                The component will receive two props:{" "}
                <code className={css.highlight}>values</code> and{" "}
                <code className={css.highlight}>onChange</code>. The first one
                is an object with all current values of inputs that also get
                passed to the design function as{" "}
                <code className={css.highlight}>inputs</code>. The second one is
                a function handler that allows changing the value of a specific
                input.
              </div>
              <pre className={css.middlePart}>
                <code>{`export const ExtraUI = (props) => {
  const { values, onChange } = props;

  const clickHandler = (e) => {
    onChange(e, 'columns', Math.floor(Math.random() * 8));
  };

  return (`}</code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}>
                In this example, a button gets rendered that when clicked
                changes the value of an input called "columns" to a random value
                between 0 and 7. This will trigger an update in the Mechanic app
                and design function.
              </div>
              <pre className={css.middlePart}>
                <code>{`    <button onClick={clickHandler}>
      Randomize columns!
    </button>`}</code>
              </pre>
            </div>
            <div className={css.subsection}>
              <div className={css.subsectionText}></div>
              <pre className={css.lastPart}>
                <code>
                  {`  );
};`}
                </code>
              </pre>
            </div>
          </div>

          <div className={css.section}>
            <Heading3 id="preloading">Preloading</Heading3>

            <p className={css.leftText}>
              Design functions can often rely on external resources such as
              images or webfonts. As these can be quite heavy in file size they
              can slow down the rendering of a design function. To avoid this,
              Mechanic provides a set of helper functions you can use to preload
              external assets. Loading images and fonts through these functions
              makes sure every resource is only loaded once per design function
              and then cached for quick access on subsequent function runs.
            </p>

            <p className={css.leftText}>
              Mechanic provides two preloading functions{" "}
              <code className={css.highlight}>preload</code> and{" "}
              <code className={css.highlight}>preloadFont</code> as well as a
              more generic <code className={css.highlight}>memo</code> function.
              All functions can be imported directly from the{" "}
              <code className={css.highlight}>@mechanic-design/core</code>{" "}
              module.
            </p>

            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <h4>
                  <code className={css.highlight}>preload</code>
                </h4>
              </div>
              <pre className={css.firstPart}>
                <code>{`import { preload } from "@mechanic-design/core";
`}</code>
              </pre>
            </div>

            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  The <code className={css.highlight}>preload</code> function
                  allows you to preload any resource. It's using the native{" "}
                  <code className={css.highlight}>fetch</code> API to
                  asynchronously load and return a base64 string of the loaded
                  resource, which in most cases should be ready for use.
                </p>
                <p>
                  You should use the{" "}
                  <code className={css.highlight}>preload</code> function
                  whenever you cannot know the exact resource to load at build
                  time. So for example if the resource needs to be fetched via
                  the network or if it depends on user input.
                </p>
              </div>
              <pre className={css.lastPart}>
                <code>{`const myImage = await preload('https://example.com/image.png');

// SVG Example
mechanic.done(\`
  <svg>
    <img href={myImage} />
  </svg>
\`);
`}</code>
              </pre>
            </div>

            <div className={classnames(css.subsection, css.withSpacing)}>
              <div className={css.subsectionText}>
                <h4>
                  <code className={css.highlight}>preloadFont</code>
                </h4>
              </div>
              <pre className={css.firstPart}>
                <code>{`import { preloadFont } from "@mechanic-design/core";
`}</code>
              </pre>
            </div>

            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  <code className={css.highlight}>preloadFont</code> is a
                  specialized font preloader. It fetches a font file over the
                  network and then installs it into the design function's
                  document as a webfont. It returns the name of the webfont to
                  be used wherever you can specify a{" "}
                  <code className={css.highlight}>font-family</code> property.
                </p>
              </div>
              <pre className={css.lastPart}>
                <code>{`const font = await preloadFont('https://example.com/font.woff2');

// Canvas Example
ctx.font = \`24px \${font}\`;
`}</code>
              </pre>
            </div>

            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  You should use{" "}
                  <code className={css.highlight}>preloadFont</code>
                  whenever you need to work with a webfont in a CSS-like way.
                </p>
              </div>
            </div>

            <div className={classnames(css.subsection, css.withSpacing)}>
              <div className={css.subsectionText}>
                <h4>
                  <code className={css.highlight}>memo</code>
                </h4>
              </div>
              <pre className={css.firstPart}>
                <code>{`import { memo } from "@mechanic-design/core";
`}</code>
              </pre>
            </div>

            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  Lastly there is the{" "}
                  <code className={css.highlight}>memo</code> util. It works
                  similar to the <code className={css.highlight}>useMemo</code>{" "}
                  hook in React. With this you can create your own preloader
                  that allow you to cache anything. For example the result of an
                  expensive calculation or an already parsed API response.
                </p>
              </div>
              <pre className={css.lastPart}>
                <code>{`const apiRespone = await memo(async () => {
  // This is a potentially slow call to an external API.
  // The way this is set up will only re-run the API call
  // the id we pass in changes.
  return await getIdFromAPI(id);
}, [id]);
`}</code>
              </pre>
            </div>

            <div className={css.subsection}>
              <div className={css.subsectionText}>
                <p>
                  <code className={css.highlight}>memo</code> takes two
                  arguments. The function that returns the value you want to
                  cache and an array of dependencies. The dependencies are used
                  to determine if the cached value should be used or if the
                  function should be re-run. The above example will always
                  re-run the API call if it gets called with an ID it hasn't
                  seen before. If it gets called with a known ID it will just
                  return the cached value.
                </p>
                <p>
                  You should use <code className={css.highlight}>memo</code>
                  whenever you want to make sure a computation only runs once
                  per design function.
                </p>
              </div>
            </div>
          </div>

          <div className={css.section}>
            <Heading3 id="dive-deeper">Finally</Heading3>
            <p className={css.leftText}>
              If you want to dive even deeper into Mechanic we suggest checking
              out the{" "}
              <a
                href="https://github.com/designsystemsinternational/mechanic"
                target="_blank"
                rel="noreferrer">
                GitHub page
              </a>
              .
            </p>
            <p className={css.leftText}>
              We are very interested in hearing feedback from you, so{" "}
              <a href="https://forms.gle/uBTn8oVThZHVghV89">
                please let us know what you&nbsp;think
              </a>
              .
            </p>
          </div>
        </div>
      </main>

      <div className={grid.grid}>
        <Subscribe
          className={classnames(css.subscribe, grid.col)}
          variant={"blue"}
        />
      </div>

      <Footer subscribed={subscribed} variant={"blue"} />

      <SuccessPopup setSubscribed={setSubscribed} />
    </Layout>
  );
};

export default DocsPage;

export const query = graphql`
  query {
    examples: allFile(
      filter: {
        extension: { eq: "json" }
        sourceInstanceName: { eq: "examples" }
        relativeDirectory: { eq: "v2.0.0-beta.9" }
      }
    ) {
      nodes {
        childJson {
          name
          description
        }
      }
    }
    inputs: allFile(
      filter: {
        extension: { eq: "json" }
        sourceInstanceName: { eq: "inputs" }
        relativeDirectory: { eq: "v2.0.0-beta.9" }
      }
    ) {
      nodes {
        childJson {
          order
          name
          title
          description
          attributes
          examples {
            code
            attributes
          }
          properties {
            name
            description
          }
        }
      }
    }
    templates: allFile(
      filter: {
        extension: { eq: "json" }
        sourceInstanceName: { eq: "templates" }
        relativeDirectory: { eq: "v2.0.0-beta.9" }
      }
    ) {
      nodes {
        childJson {
          name
          description
        }
      }
    }
    logo: file(name: { eq: "logo" }) {
      childImageSharp {
        gatsbyImageData(width: 30)
      }
    }
  }
`;
